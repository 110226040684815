export const MODEL_CONFIG = {
    // Chat Models
    'deepseek-v3': {
      type: 'chat',
      supportsFiles: true,
      allowedFileTypes: {
        'text/csv': '.csv',
        'application/pdf': '.pdf', 
        'text/plain': '.txt',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'image/jpeg': '.jpg,.jpeg',
        'image/png': '.png'
      }
    },
    'deepseek-r1': {
      type: 'chat',
      supportsFiles: true,
      allowedFileTypes: {
        'text/csv': '.csv',
        'application/pdf': '.pdf', 
        'text/plain': '.txt',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'image/jpeg': '.jpg,.jpeg',
        'image/png': '.png'
      }
    },
    'llama-3-8b-instruct': {
      type: 'chat',
      supportsFiles: true,
      allowedFileTypes: {
        'text/csv': '.csv',
        'application/pdf': '.pdf', 
        'text/plain': '.txt',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'image/jpeg': '.jpg,.jpeg',
        'image/png': '.png'
      }
    },
    'gpt-4o-mini': {
      type: 'chat',
      supportsFiles: true,
      allowedFileTypes: {
        'text/csv': '.csv',
        'application/pdf': '.pdf', 
        'text/plain': '.txt',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'image/jpeg': '.jpg,.jpeg',
        'image/png': '.png'
      }
    },
    'gpt-o3-mini': {
      type: 'chat',
      supportsFiles: true,
      allowedFileTypes: {
        'text/csv': '.csv',
        'application/pdf': '.pdf', 
        'text/plain': '.txt',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'image/jpeg': '.jpg,.jpeg',
        'image/png': '.png'
      }
    },
    'gpt-4o': {
      type: 'chat',
      supportsFiles: true,
      allowedFileTypes: {
        'text/csv': '.csv',
        'application/pdf': '.pdf', 
        'text/plain': '.txt',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'image/jpeg': '.jpg,.jpeg',
        'image/png': '.png'
      }
    },
    'claude-3.5-haiku': {
      type: 'chat',
      supportsFiles: true,
      allowedFileTypes: {
        'text/csv': '.csv',
        'application/pdf': '.pdf', 
        'text/plain': '.txt',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'image/jpeg': '.jpg,.jpeg',
        'image/png': '.png'
      }
    },
    'claude-3.5-sonnet': {
      type: 'chat',
      supportsFiles: true,
      allowedFileTypes: {
        'text/csv': '.csv',
        'application/pdf': '.pdf', 
        'text/plain': '.txt',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'image/jpeg': '.jpg,.jpeg',
        'image/png': '.png'
      }
    },
  
    // Image Models
    'flux-schnell': {
      type: 'image',
      supportsFiles: false
    },
    'sticker-maker': {
      type: 'image',
      supportsFiles: false
    },
    'sdxl-lightning-4step': {
      type: 'image',
      supportsFiles: false
    }
  };
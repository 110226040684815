import React from 'react';
import { X, Bot, Sparkles, Zap, ChevronRight } from 'lucide-react';

const OnboardingModal = ({ isOpen, onClose, isDarkMode }) => {
  if (!isOpen) return null;

  const modelInfo = [
    {
      type: 'Chat Models',
      icon: <Bot size={20} />,
      models: [
        { name: 'DeepSeek-V3', credits: 1 },
        { name: 'DeepSeek-R1', credits: 2 },
        { name: 'Llama-3-8B-Instruct', credits: 1 },
        { name: 'GPT-o3-Mini', credits: 3 },
        { name: 'GPT-4o-Mini', credits: 2 },
        { name: 'GPT-4o', credits: 4 },
        { name: 'Claude-3.5-Haiku', credits: 2 },
        { name: 'Claude-3.5-Sonnet', credits: 4 }
      ]
    },
    {
      type: 'Image Models',
      icon: <Sparkles size={20} />,
      models: [
        { name: 'Flux Schnell', credits: 1, unit: 'image' },
        { name: 'Sticker Maker', credits: 1, unit: 'image' },
        { name: 'SDXL Lightning', credits: 1, unit: 'image' }
      ]
    }
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 sm:p-0">
      <div className={`w-full max-w-2xl max-h-[90vh] overflow-y-auto rounded-xl shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        {/* Header - Fixed at top */}
        <div className={`sticky top-0 z-10 p-4 sm:p-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex justify-between items-center">
            <h2 className={`text-xl sm:text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Welcome to FlexFlow!
            </h2>
            <button 
              onClick={onClose} 
              className={`p-2 rounded-lg transition-colors ${isDarkMode ? 'text-gray-400 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'}`}
            >
              <X size={20} />
            </button>
          </div>
        </div>

        {/* Content - Scrollable */}
        <div className="p-4 sm:p-6 space-y-6">
          <div>
            <h3 className={`text-lg sm:text-xl font-semibold mb-3 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Getting Started
            </h3>
            <p className={`text-sm sm:text-base ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              FlexFlow uses a credit system to provide access to powerful AI models. You start with 100 free credits!
            </p>
          </div>

          <div>
            <h3 className={`text-lg sm:text-xl font-semibold mb-3 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Available Models & Credit Costs
            </h3>
            <div className="space-y-3">
              {modelInfo.map((category) => (
                <div key={category.type} className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                  <div className="flex items-center gap-2 mb-3">
                    {category.icon}
                    <h4 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {category.type}
                    </h4>
                  </div>
                  <div className="grid gap-2">
                  {category.models.map((model) => (
                  <div key={model.name} 
                    className={`flex justify-between items-center p-2 rounded ${
                      isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-100'
                    } transition-colors`}
                  >
                    <span className={`text-sm sm:text-base ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                      {model.name}
                    </span>
                    <span className={`flex items-center gap-1 text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                      <Zap size={14} className="text-theme-500" />
                      {model.credits} {model.credits === 1 ? 'credit' : 'credits'}/{model.unit || '500 tokens'}
                    </span>
                  </div>
                ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className={`text-lg sm:text-xl font-semibold mb-3 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Need More Credits?
            </h3>
            <p className={`text-sm sm:text-base ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              You can purchase additional credits anytime through the credits widget in the sidebar.
            </p>
          </div>
        </div>

        {/* Footer - Fixed at bottom */}
        <div className={`sticky bottom-0 p-4 sm:p-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <button
            onClick={onClose}
            className="w-full bg-theme-500 text-white py-3 rounded-lg hover:bg-theme-600 font-medium flex items-center justify-center gap-2 transition-colors"
          >
            <span>Get Started</span>
            <ChevronRight size={18} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;